import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import Fujairahcenter from '../../images/fujairah-cultural-center2.webp';
import Kalbatv from '../../images/kalba-tv.webp';
import Khalifauni from '../../images/khalifa-university.webp';
import Thumbay from '../../images/thumbay-group.webp';
import Americanschool from '../../images/american-school.webp';
import './projects.css';
function projects() {
    return (

        <>
            <div className="boxed_wrapper" id="project">
                <Helmet>
                    <title>Our Recently Completed Acoustic Projects Vol.5</title>
                    <meta name="description" content="We are extremely fortunate to be part of these iconic projects (vol.5)." />
                    <meta property="og:title" content="Our Recently Completed Acoustic Projects" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="../../images/moovgroup-office.webp" />
                    <link rel="canonical" href="https://www.moovgroup.com/projects-v5" />
                    <link rel="alternate" href="https://www.moovgroup.com/projects-v5" hreflang="en-GB" />
                </Helmet>
                <Header />
                <div id="moov">
                    <section className="breadcrumb-section projects-bg">
                        <div className="container">
                            <span className="page-tag">Recently</span>
                            <h1 className="page-title">Completed Projects</h1>
                        </div>
                    </section>

                    <section className="portfolio-section section-gap" id="portfolio">
                        <div className="container">
                            <div className="portfolio-items">
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Fujairahcenter} alt="Fujairah Cultural Center" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="culturalcenter-img"></div>
                                                <div className="img-bot">
                                                    <div className="culturalcenter-img2"></div>
                                                    <div className="culturalcenter-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">10.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/fujairah-auditorium-acoustics">Fujairah Cultural Center Auditorium</Link></h5>
                                                <p>Location: Fujairah, UAE</p>
                                                <p>Client: Ministry of Public Works</p>
                                                <p>Consultant  Space Consultant</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">The Fujairah Cultural Centre Auditorium is a premier venue designed to host a wide array of cultural events, performances, and gatherings. Located in the vibrant city of Fujairah, UAE, it serves as a cultural hub, bringing together artists, performers, and the community. The auditorium is equipped with state-of-the-art facilities and is tailored to provide an exceptional auditory experience.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">This project involves acoustic design, supply, and installation at the Fujairah Cultural Centre Auditorium. Complete acoustic treatment on walls and ceilings, including detailed analysis, customized design solutions, high-quality material selection, professional installation, and regular inspections to ensure optimal sound quality.</p>
                                                <Link to="/fujairah-auditorium-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Kalbatv} alt="Kalba TV" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="kalbatv-img"></div>
                                                <div className="img-bot">
                                                    <div className="kalbatv-img2"></div>
                                                    <div className="kalbatv-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">09.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/kalba-tv-acoustics">Kalba TV</Link></h5>
                                                <p>Location: Sharjah, UAE</p>
                                                <p>Client: Govt. of Sharjah, Directorate of Public Works, UAE</p>
                                                <p>Consultant C.A.B. Consultant</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">Kalba TV, located in Sharjah, UAE, is a local documentary channel dedicated to showcasing the enchanting beauty of the Eastern Coast and the Eastern Region. Renowned for its picturesque natural scenery, rich history, and cultural heritage, this region also boasts remarkable environmental diversity. Kalba TV aims to bring these unique aspects to a wider audience, celebrating and preserving the area's legacy.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">The project involves the supply, erection, testing, and commissioning of complete acoustic treatments for Kalba TV’s studios, control room, and supporting rooms. Covering a carpet area of approximately 500 square meters, the acoustic enhancements are designed to ensure optimal sound quality and functionality across all broadcast and production spaces.</p>
                                                <Link to="/kalba-tv-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Khalifauni} alt="Khalifa University" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="khalifauniversity-img"></div>
                                                <div className="img-bot">
                                                    <div className="khalifauniversity-img2"></div>
                                                    <div className="khalifauniversity-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">08.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/khalifa-university-acoustics">Khalifa University</Link></h5>
                                                <p>Location: Abu Dhabi, UAE</p>
                                                <p>Client: Khalifa University</p>
                                                <p>Consultant  Lead Consultant</p>
                                                <p>Main Contractor  General Contracting LLC</p>
                                                <p>Contractor  Fibrex Construction Group</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">The newly-established Khalifa University of Science and Technology combines The Masdar Institute of Science and Technology, Khalifa University of Science, Technology and Research and The Petroleum Institute into one world-className, research-intensive institution, seamlessly integrating research and education to produce world leaders and critical thinkers in applied science and engineering.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">Khalifa University's acoustic enhancement project involves installing wooden acoustic panels in five colors, wood wool panels in meeting rooms, and fabric cladding in multiuse halls. Covering 10,000 square meters, we aimed at improving sound quality and aesthetics campus-wide.</p>
                                                <Link to="/khalifa-university-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Thumbay} alt="Thumbay Group" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="thumbayinstitute-img"></div>
                                                <div className="img-bot">
                                                    <div className="thumbayinstitute-img2"></div>
                                                    <div className="thumbayinstitute-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">07.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/thumbay-group-acoustics">Thumbay - Gulf Medical University</Link></h5>
                                                <p>Location: Ajman, UAE</p>
                                                <p>Client: Thumbay Group</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">The Thumbay Institute for Precision Medicine and Translational Research, Population Health, and Health Workforce Development are hubs for research and post-graduate studies. They also focus on continuous professional development, supporting healthcare advancements. These institutes strive to bridge the gap between research and real-world applications. They aim to create impactful solutions to healthcare challenges.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">The scope of services includes the design, supply, and acoustic treatment for four lecture halls, each accommodating **180-200**seats. This initiative likely aims to enhance the educational environment and facilities to support high-quality learning and development for medical and healthcare students.</p>
                                                <Link to="/thumbay-group-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Americanschool} alt="American school" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="americanschool-img"></div>
                                                <div className="img-bot">
                                                    <div className="americanschool-img2"></div>
                                                    <div className="americanschool-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">06.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/american-school-acoustics">American School of Creative Science</Link></h5>
                                                <p>Location: Sharjah, UAE</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">The American School of Creative Science Al Layyah (ASCS Al Layyah) welcomes prospective parents and students from all nationalities. We aim to provide a unique education that is second to none. Our curriculum nurtures, cultivates, and fosters creative and critical thinking skills. We strive for academic excellence with strong moral values to develop successful and contributing members of a global society.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p>Our scope for this project includes</p>
                                                <p>36 Classrooms: Acoustic fit-out works (Wall & Ceiling)</p>
                                                <p>Library: Acoustic wall and ceiling works</p>
                                                <p>Laboratory: Acoustic fit-out works</p>
                                                <p>Play Area: Acoustic works (Wall & Ceiling)</p>
                                                <p>Indoor Play Area: Acoustic fit-out works</p>
                                                <p>Corridors: Sound absorption panels</p>
                                                <Link to="/american-school-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-items">
                                    <div className="portfolio-item portfolio-style-four">
                                        <span className="line-footer"></span>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="portfolio-container1">
                                                    <Link to="/projects-v4" className="portfolio-previous">&laquo; Previous</Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="portfolio-container2">
                                                    <Link to="/projects-v6" className="portfolio-next">Next &raquo;</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default projects;