import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import Etisalat from '../../images/etisalat-auditorium.webp';
import Dancefm from '../../images/dancefm.webp';
import Whotel from '../../images/whotel.webp';
import Hilton from '../../images/hiltonhotel.webp';
import Utc from '../../images/utc.webp';
import './projects.css';
function projects() {
    return (

        <>
            <div className="boxed_wrapper" id="project">
                <Helmet>
                    <title>Our Recently Completed Acoustic Projects Vol.6</title>
                    <meta name="description" content="We are extremely fortunate to be part of these iconic projects (vol.6)." />
                    <meta property="og:title" content="Our Recently Completed Acoustic Projects" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="../../images/moovgroup-office.webp" />
                    <link rel="canonical" href="https://www.moovgroup.com/projects-v6" />
                    <link rel="alternate" href="https://www.moovgroup.com/projects-v6" hreflang="en-GB" />
                </Helmet>
                <Header />
                <div id="moov">
                    <section className="breadcrumb-section projects-bg">
                        <div className="container">
                            <span className="page-tag">Recently</span>
                            <h1 className="page-title">Completed Projects</h1>
                        </div>
                    </section>

                    <section className="portfolio-section section-gap" id="portfolio">
                        <div className="container">
                            <div className="portfolio-items">
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Etisalat} alt="Etisalat Auditorium" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="etisalatauditorium-img"></div>
                                                <div className="img-bot">
                                                    <div className="etisalatauditorium-img2"></div>
                                                    <div className="etisalatauditorium-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">05.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/etisalat-auditorium-acoustics">Etisalat Auditorium</Link></h5>
                                                <p>Location: Dubai, UAE</p>
                                                <p>Client: Etisalat</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">Emirates Telecommunications Corporation, branded as Etisalat, is a leading multinational telecommunications services provider based in the UAE. With operations spanning across 16 countries in Asia, the Middle East, and Africa, Etisalat is known for delivering top-notch communication solutions and services.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">Our project involved the supply and installation of seating arrangements for the Etisalat Auditorium. This included 206 standard seats, designed for comfort and durability, and 10 VVIP seats, which offer an elevated level of luxury and exclusivity. The installation process was meticulously planned and executed to ensure the seating provided an optimal experience for all attendees. Our team worked diligently to enhance the overall ambiance of the auditorium.</p>
                                                <Link to="/etisalat-auditorium-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Dancefm} alt="Dance FM" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="dancefm-img"></div>
                                                <div className="img-bot">
                                                    <div className="dancefm-img2"></div>
                                                    <div className="dancefm-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">04.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/dance-fm-acoustics">Dance FM</Link></h5>
                                                <p>Location: Media City, Dubai, UAE</p>
                                                <p>Client: SHOC Middle East LLC</p>
                                                <p>Main Contractor  Almas Design & Décor LLC</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">About Dance FM 97.8 is the UAE's premier dance station, exclusively bringing you the best in electronic music. Broadcasting from Media City, it features a diverse mix of over 100 local and international artists. The station's mission is to energize the airwaves with a unique blend of electronic beats, offering listeners a vibrant and dynamic music experience.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">Our work encompassed the complete supply, installation, testing, and commissioning of acoustic treatments for the on-air studio. This involved the precise application of soundproofing materials to ensure optimal audio quality. The project aimed to deliver a flawless sound experience for both the station’s hosts and its audience.</p>
                                                <Link to="/dance-fm-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Whotel} alt="Whotel" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="whotel-img"></div>
                                                <div className="img-bot">
                                                    <div className="whotel-img2"></div>
                                                    <div className="whotel-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">03.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/w-hotel-acoustics">W Hotel & Residences</Link></h5>
                                                <p><b>Location:</b> Palm Jumeirah - Dubai, UAE</p>
                                                <p><b>Client:</b> Al Sharq Investment</p>
                                                <p><b>Consultant:</b> HolFord</p>
                                                <p><b>Main Contractor:</b> Alfuttaim Carillion ( AF construction LLC )</p>
                                                <p><b>Sub Contractor:</b> Moov general trading LLC</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">This stunning resort on the Crescent of Palm Jumeirah offers world-class hospitality with opulent amenities. The complex includes a luxurious spa, several pool areas, and enchanting water features. With approximately 100,000 m² of space, it boasts 300 elegant hotel rooms.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">Our scope for this project included the installation of sound absorption panels on walls and ceilings for various service rooms. This acoustic treatment covered rooms such as the generator room, fire and irrigation pump room, AHU room, pump room, dosing plant room, ETS plant room, and FCU room. These measures were implemented in both the hotel and residence basement areas.</p>
                                                <Link to="/w-hotel-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Hilton} alt="Hilton Hotel" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="hiltonhotel-img"></div>
                                                <div className="img-bot">
                                                    <div className="hiltonhotel-img2"></div>
                                                    <div className="hiltonhotel-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">02.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/hilton-hotel-acoustics">Double Tree By Hilton Hotel</Link></h5>
                                                <p><b>Location :</b> Dubai, UAE</p>
                                                <p><b>Client :</b> Dubai Properties</p>
                                                <p><b>Contractor :</b> Khansaheb Interiors</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">The project consists of 2B+G+12 Floors, 238 key Hotel Tower which is already constructed as Shell & Core finish including external cladding. The hotel tower covers a total BUA of approx. 37,662m2 and consists of two basement level car parking, main kitchen, laundry, restaurants, retail shops, Reception, Lobby, Banquet Hall, Business Centre, Swimming Pool, Health Club, Guest Rooms & Suites including all MEP.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">We installed sound absorption panels in the Upper Basement, 1st Basement, Ground Floor, First Floor, and Top Floor of the DoubleTree Hilton Hotel. These panels improve the acoustic environment, reducing noise levels to ensure a peaceful atmosphere for guests and staff.</p>
                                                <Link to="/hilton-hotel-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Utc} alt="utc" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="utc-img"></div>
                                                <div className="img-bot">
                                                    <div className="utc-img2"></div>
                                                    <div className="utc-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">01.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/utc-aerospace-systems-acoustics">UTC Aerospace Systems</Link></h5>
                                                <p><b>Location :</b> Dubai, UAE</p>
                                                <p><b>Client :</b> UTC Aerospace systems</p>
                                                <p><b>Contractor :</b> MOOV Construction Solutions LLC</p>
                                                <span className="completed-date mb-0 mt-2">About Project: </span>
                                                <p className="about-content">UTC Aerospace Systems is one of the world’s largest suppliers of technologically advanced aerospace and defence products. They design, manufacture, and service systems and components and provide integrated solutions for commercial, regional, business, and military aircraft, helicopters, and other platforms. Their products are renowned for their quality and innovation. They are also a major supplier to international space programs.</p>
                                                <span className="completed-date mb-0 mt-2">Our Scope:</span>
                                                <p className="scope-content">We created a soundproof chamber with various sound proofing acoustic materials like, M S Framework, CFAB, MLVB, Gypsum Board etc. where the setup ensured stringent noise reduction, vital for aerospace testing and manufacturing.</p>
                                                <Link to="/utc-aerospace-systems-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-items">
                                    <div className="portfolio-item portfolio-style-four">
                                        <span className="line-footer"></span>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="portfolio-container1">
                                                    <Link to="/projects-v5" className="portfolio-previous">&laquo; Previous</Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default projects;